import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MyFundiService } from '../../../services/myFundiService';

@Component({
  selector: 'client-role',
    templateUrl: './client.component.html'
})
export class ClientRoleComponent {
}

