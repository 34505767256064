import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MyFundiService } from '../../../services/myFundiService';
import { Router } from '@angular/router';

@Component({
    selector: 'guest-role',
    templateUrl: './guest.component.html'
})
export class GuestRoleComponent {
}

